import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

$(document).ready(function () {

    let slides = $('.home-slider__slide');

    let pages = null;

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let slider = $('[js-slider]');

    let sliderDelay = slider.data('sliderDelay');

    slider.on('init', async function (e, slick) {

        await sleep(300);

        $('[js-slider]').addClass('ready');
        slides.eq(0).addClass('active');
        pages = $('.slick-dots li');
        pages.eq(0).addClass('active');

    }).slick({
        arrows: false,
        pagination: true,
        dots: true,
        speed: 1000,
        fade: true,
        autoplay: true,
        autoplaySpeed: sliderDelay,
        customPaging: function (slick, index) {
            return `<button role="button" class="page"><span class="arc"><span class="arc__left" style="transition-duration: ${sliderDelay / 2}ms; transition-delay: ${sliderDelay / 2}ms"></span><span class="arc__right" style="transition-duration: ${sliderDelay / 2}ms"></span></span>` + (index + 1) + '</button>';
        },

    }).on('beforeChange', function (event, slick, currentSlide, nextSlide) {

        slides.eq(nextSlide).addClass('active');

        pages.removeClass('active');

        if (pages && pages.eq(nextSlide).length) {
            pages.eq(nextSlide).addClass('active');
        }

    }).on('afterChange', function (event, slick, currentSlide) {
        Lazyload.init();
        slides.each(function (i) {
            if (i != currentSlide) {
                slides.eq(i).removeClass('active');
            }
        });

    })


    /**
     * Hover area¨block
     * *************************************************/

    const imageHover = $("[js-hover-img]");
    const parentDest = $('#dedi-area__img');
    const dest = $('#dedi-area__img__inner');

    let getCurrentPicSrc = function () {
        return dest.css('background-image').replace(/.*\s?url\([\'\"]?/, '').replace(/[\'\"]?\).*/, '');
    }

    const originalPicture = getCurrentPicSrc();
    const restoreTimeout = 500;

    let timeoutLeave = null;

    imageHover.on('mouseover', function (e) {
        clearTimeout(timeoutLeave);
        var el = $(this);
        var src = el.data('image');
        if (src) {
            replaceImage(src);
        }
    });

    imageHover.on('mouseleave', function (e) {
        timeoutLeave = setTimeout(restoreOriginal, restoreTimeout);
    });

    var replaceImage = function (src) {

        parentDest.css('background-image', dest.css('background-image'));

        dest.fadeOut(0, function () {
            var img = new Image();
            img.src = src;

            img.onload = function () {
                dest.css('background-image', 'url(' + src + ')');
                dest.fadeIn(500);
            }
        });
    }

    var restoreOriginal = function () {
        replaceImage(originalPicture);
    }

});
/***************************************************
 * PRICES CHARTS
 * *************************************************/


document.addEventListener("DOMContentLoaded", function () {
    const ctx = document.getElementById('priceChart').getContext('2d');
    const canvas = document.getElementById('priceChart');
    const data = JSON.parse(canvas.getAttribute('data-prices'));  // Récupère et parse les données

    // Les données fournies
    const months = JSON.parse(data).months;
    const priceValues =JSON.parse(data).values;
    // Création du graphique
    new Chart(ctx, {
        type: 'line', // Type de graphique
        data: {
            labels: months, // Les labels pour l'axe X
            datasets: [ {
                label: 'Prix (€/kWh)', // Nom de la courbe
                data: priceValues, // Données pour l'axe Y
                borderColor: '#4b0082', // Couleur de la ligne
                backgroundColor: 'rgba(75, 0, 130, 0.1)', // Couleur de remplissage sous la ligne
                borderWidth: 2, // Épaisseur de la ligne
                pointRadius: 5, // Taille des points
                pointBackgroundColor: '#4b0082', // Couleur des points
            } ]
        },
        options: {
            plugins: {
                legend: {
                    display: false // Désactive la légende
                }
            },
            responsive: true,
            scales: {
                x: {
                    title: {
                        display: false,
                        text: 'Mois', // Titre de l'axe X
                    },
                    ticks: {
                        maxRotation: 45, // Rotation des labels
                        minRotation: 45,
                    },
                },
                y: {
                    title: {
                        display: false,
                        text: 'Prix (€)', // Titre de l'axe Y
                    },
                    beginAtZero: true, // Commence à 0
                }
            }
        }
    });
});